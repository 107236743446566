<template>
  <v-row class="extras pt-5 pa-2 main-bg-rebel" v-if="extra != null">


    <v-col cols="12" sm="2" md="2" lg="2" class="d-none d-md-inline ml-auto">
      <GalleryGrid :gallery="extra.gallery2" :nbCols="1"/>
    </v-col>


    <v-col cols="12" sm="9" md="7" lg="7" class="mx-auto" style="padding:%;">
      <h1 style="font-size:70px;">Ateliers</h1>
      <v-divider/>
      <br>
      <p class="text pa-2" v-html="nl2br(extra.text)"></p>
    </v-col>

    <v-col cols="6" sm="2" md="2" lg="2" class="d-sm-none mr-auto">
      <GalleryGrid :gallery="extra.gallery2" :nbCols="1"/>
    </v-col>
    <v-col cols="6" sm="2" md="2" lg="2" class="d-sm-none mr-auto">
      <GalleryGrid :gallery="extra.gallery3" :nbCols="1"/>
    </v-col>

    <v-col cols="12" sm="2" md="2" lg="2" class="d-none d-sm-inline mr-auto">
      <GalleryGrid :gallery="extra.gallery3" :nbCols="1"/>
      <div class="d-md-none">
        <GalleryGrid :gallery="extra.gallery2" :nbCols="1"/>
      </div>
    </v-col>

    <!-- <v-col cols="12" class="d-sm-none">
      <GalleryGrid :gallery="extra.gallery" :nbCols="1"/>
    </v-col> -->

  </v-row>
</template>

<style>
  .extras .text{
    font-size: 16px;
  }
  .extras .text h2{
    font-size:30px;
  }
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

import GalleryGrid from '@/components/main-layout/GalleryGrid.vue'

export default {
  name: 'extras',
  components: { GalleryGrid },
  data: () => ({
    extra: null
    
  }),
  async mounted(){
      let extras = await this.$store.dispatch('app/fetchEntities', { entityType: "extra" })
      this.extra = extras[0]
      this.$store.dispatch('app/incStat', '/extras')
  },
  methods: {
    
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      return newStr
    },
  },
  computed: {
    
    
  }
}
</script>
